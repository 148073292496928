import {axiosCall, getAPIConfig} from "../app.config";
import axios from "axios";

export const createPredictionApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PREDICTION_CREATE_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const getPredictionStatusApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PREDICTION_GET_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const getPredictionsByProductApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PREDICTION_BY_PRODUCT_ID_GET_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);
}

export const sendFeedbackApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_SEND_FEEDBACK_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);

}

export const getPredictionFeedbackApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_PREDICTION_FEEDBACK_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);

}

export const generatePromptApi = async (params, inputData) => {
    const verificationKey = btoa(params).toString('base64');
    const envKey = 'REACT_APP_API_GENERATE_PROMPT_PATH';
    const axiosConfig = getAPIConfig(envKey, verificationKey, 'POST',inputData);

    return  await axiosCall(axiosConfig);

}
