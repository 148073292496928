import {
    BlockStack, Button, Divider, IndexTable, InlineStack, ProgressBar, Text, Thumbnail, Modal, MediaCard, Card, Badge
} from "@shopify/polaris";

import {ImageAddIcon} from "@shopify/polaris-icons";
import React, {useContext, useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {handleMouseMoveZoom, handleMouseOutZoom} from "./common/util";
import {AppContext} from "../lib/contextLib";
import ThumbFeedback from "./common/thumbFeedback";
import useEmblaCarousel from "embla-carousel-react";
import {DotButton, useDotButton} from '../components/EmblaCarouselDotButton';
import {
    PrevButton,
    NextButton,
    usePrevNextButtons
} from '../components/EmblaCarouselArrowButtons';
import {CreateDrawer} from "./CreateDrawer";


export default function ProductsIndexTableRow({
                                                  productID,
                                                  productName,
                                                  productStatus,
                                                  totalInventory,
                                                  totalVariants,
                                                  productImage,
                                                  productImages,
                                                  predictions,
                                                  isPredictionCreated,
                                                  selectedTab,
                                              }, index, selectedProduct) {
    const {search} = useLocation();
    const params = new URLSearchParams(search);
    const [isViewDrawerOpen, setIsViewDrawerOpen] = useState(false);
    const [isCreateDrawerOpen, setIsCreateDrawerOpen] = useState(false);
    const [selectedProductID, setSelectedProductID] = useState(null);
    const [slideCount, setSlideCount] = useState(0);

    const OPTIONS = {loop: true}
    const SLIDES = Array.from(Array().keys())


    const [emblaRef, emblaApi] = useEmblaCarousel(OPTIONS)

    const {selectedIndex, scrollSnaps, onDotButtonClick} =
        useDotButton(emblaApi)

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)

    const {
        isRefresh, setIsRefresh,
        shopState, setShopState,
        bannerMessage, setBannerMessage,
        openPlanModal, setOpenPlanModal
    } = useContext(AppContext);


    const handelOnCreateButton = () => {
        if (shopState.predictions_left < 1) {
            const bannerData = {
                title: 'Upgrade needed!',
                tone: 'warning',
                actionCallback: () => {
                    setOpenPlanModal(true);
                    console.log('Upgrade needed! pressed')
                },
                actionContent: 'Upgrade',
                description: 'You have reached your limit of AI Videos. Please upgrade your plan to create more AI Videos.'
            }
            setBannerMessage(bannerData)
            return;
        }


        setSelectedProductID(productID)
        setIsCreateDrawerOpen(true)


    }

    const filteredPredictions = predictions.filter(prediction => prediction.state === 'completed')

    const aiVideosColRender = filteredPredictions.map((prediction, index) => {
        return (
            <div className={'embla__slide'}>
                <video
                    key={prediction.video.url}
                    controls
                    height={'250px'}
                >
                    <source src={prediction.video.url} type="video/mp4"/>
                </video>
                <br/>

                <ThumbFeedback
                    id={prediction?.id}
                    state={prediction.state}
                    CreatedAt={prediction.created_at}
                />
            </div>

        )
    })

    useEffect(() => {
        console.log('selectedTabIndex', selectedTab);
        setSlideCount(aiVideosColRender.length <= 3 ? aiVideosColRender.length : aiVideosColRender.length + 1)


    }, [])

    const statusTOTone = {
        'ACTIVE': 'success',
        'ARCHIVED': '',
        'DRAFT': 'info',
    }


    return (<>
        {isCreateDrawerOpen ? <CreateDrawer
            productID={productID}
            productName={productName}
            productImage={productImage}
            productImages={productImages}
            predictions={predictions}
            isPredictionCreated={isPredictionCreated}

            isImageModalOpen={isCreateDrawerOpen}
            setIsImageModalOpen={setIsCreateDrawerOpen}
            selectedProductID={selectedProductID}
            setSelectedProductID={setSelectedProductID}
            view={1}

        /> : null}

        <IndexTable.Row
            id={productID}
            productName={productName}
            productImage={productImage}
            productImages={productImages}
            predictions={predictions}
            isPredictionCreated={isPredictionCreated}

            selected={selectedProduct}
            key={productID}
            position={index}
        >
            <IndexTable.Cell>
                <InlineStack align={'start'} gap={'200'}>
                    <div
                        onMouseMove={handleMouseMoveZoom}
                        onMouseOut={handleMouseOutZoom}
                    >
                        <Thumbnail
                            source={productImage}
                            alt={productName}
                            size="large"
                        />
                    </div>

                    <BlockStack>
                        <div style={{width: '250px', textWrap: 'wrap'}}>
                            <Text variant="bodyMd" as="span">
                                {productName}
                            </Text>
                        </div>

                        <Text variant="bodyMd" as="span">
                            ({productImages.length > 1 ? productImages.length + ' images' : '1 image'})
                        </Text>
                    </BlockStack>
                </InlineStack>
            </IndexTable.Cell>

            <IndexTable.Cell>
                <Badge tone={statusTOTone[productStatus]}>
                    {productStatus?.charAt(0).toUpperCase() + productStatus?.substring(1).toLowerCase()}
                </Badge>
            </IndexTable.Cell>

            <IndexTable.Cell>
                <Text variant="bodyMd" as="span">
                    {totalInventory} in stock for {totalVariants} variants
                </Text>
            </IndexTable.Cell>
            <IndexTable.Cell>
                <Button
                    icon={ImageAddIcon}
                    variant={'secondary'}
                    onClick={handelOnCreateButton}
                >
                    Make AI Video
                </Button>
            </IndexTable.Cell>

        </IndexTable.Row>
    </>);

}