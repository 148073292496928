import React, {Fragment, useCallback, useContext, useState} from 'react';
import {useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Panel from 'rsuite/Panel';
import {
    Table,
    Button,
    Input,
    InputGroup,
    ButtonToolbar,
    Modal,
    Form,
    IconButton,
    Grid,
    Row,
    Col,
    SelectPicker, Pagination, InputNumber
} from 'rsuite';
import {
    adminResetQuotaApi,
    adminUpdateShopDataApi,
    getAdminDataAPi,
    getLumaUsageApi
} from "../../servies/admin.services";
import ToasterMessage from "../../components/common/toaster_message";
import {CustomModal} from "../common/common_modals";
import {getAuthSession, removeAuthSession} from "../../servies/common.services";
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import {CustomToolTip} from "../common/CustomToolTip";
import {SubscriptionDetails} from "../common/LumaSubscriptionDetails";
import CustomDrawer from "../common/custom_drawer";
import {getPaymentPlan} from "../../servies/paymentPlan.services";


const {Column, HeaderCell, Cell} = Table;

export const AdminDashboard = () => {
    const {search} = useLocation();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [isResetQuota, setIsResetQuota] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [isUpdatingFirstName, setIsUpdatingFirstName] = useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);


    const [authData, setAuthData] = useState({});

    const [formValue, setFormValue] = React.useState({first_name: '', predictions_left: 0});
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedIsInstalled, setSelectedIsInstalled] = useState(1);
    const [totalUserCount, setTotalUserCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [open, setOpen] = React.useState(false);
    const [paymentPlans, setPaymentPlans] = useState([]);
    const [isPaymentPlanLoading, setIsPaymentPlanLoading] = useState(false);
    const [selectedPaymentPlan, setSelectedPaymentPlan] = useState(-1);
    const [monthlySale, setMonthlySale] = useState('all');
    const [monthlySaleOperator, setMonthlySaleOperator] = useState('all');


    const handleOpen = () => setOpen(true);

    const [lumaModalOpen, setLumaModalOpen] = React.useState(false);
    const handleLumaModalOpen = () => setLumaModalOpen(true);

    const isInstalledData = [
        {label: 'Installed', value: 1},
        {label: 'Uninstalled', value: 0},
    ];

    const monthlySaleOperatorList = [
        {label: 'All', value: 'all'},
        {label: '>=', value: '>='},
        {label: '<=', value: '<='},
        {label: '>', value: '>'},
        {label: '<', value: '<'},
        {label: '==', value: '=='},
    ]

    const handelResetQuota = (shop) => {

        const params = `${paramsData}&is_admin=true&shop=${shop}`;
        setIsResetQuota(true);
        adminResetQuotaApi(params)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                ToasterMessage.success(`Reset Quota Successfully for ${shop}`);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsResetQuota(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsResetQuota(false);
            })
    }

    const handelUpdateShopData = () => {
        setIsUpdating(true);
        const inputData = {
            'shop_data': {
                'first_name': formValue.first_name,
                'predictions_left': parseInt(formValue.predictions_left),
            }
        };

        const params = `${paramsData}&shop=${selectedRow.shop_url}`;

        adminUpdateShopDataApi(params, inputData)
            .then((res) => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                console.log('shopdata', data)

                let row = tableData.find((row) => row.shop_url === selectedRow.shop_url);
                row.first_name = data.shop_data.first_name;
                row.predictions_left = data.shop_data.predictions_left;

                console.log('row after', row, tableData)
                setTableData([...tableData]);

                ToasterMessage.success(`Updated First Name Successfully for ${selectedRow.shop_url}`);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsUpdating(false);
                console.log(errorCode, errorMessage);
            })
            .finally(() => {
                console.log('finally');
                setIsUpdating(false);
                setOpen(false);
            })
    }

    const getAdminData = (params) => {
        setIsLoading(true);

        const inputData = {
            limit: limit,
            offset: (page - 1) * limit,
            kwargs: {
                search_term: searchTerm,
                is_installed: selectedIsInstalled,
                payment_plan_id: selectedPaymentPlan,
            }
        }

        getAdminDataAPi(params, inputData)
            .then((res) => {
                console.log(res);

                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }
                setTableData(data.users);
                setTotalUserCount(data.total_users_count);

            })
            .catch((e) => {
                const errorCode = e.code;
                const errorMessage = e.message;
                ToasterMessage.error(errorMessage);
                setIsLoading(false);
                console.log(errorCode, errorMessage);
                removeAuthSession();
                navigate('/admin/login');

            })
            .finally(() => {
                console.log('finally');
                setIsLoading(false);
            })
    }

    const getPaymentPlans = (params) => {
        setIsPaymentPlanLoading(true);
        getPaymentPlan(params.toString(), true)
            .then(res => {
                const data = res.data;
                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                const labeledData = [
                    {label: 'All Plans', value: -1},
                    ...data.map(({name, is_active, id}) => ({
                        label: `${name} - ${is_active === 1 ? 'Active' : 'Inactive'}`,
                        value: id
                    }))
                ];
                setPaymentPlans(labeledData);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Payment Plan');
                console.log(reason);
            })
            .finally(() => setIsPaymentPlanLoading(false));
    }

    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);

        getAdminData(params);

        getPaymentPlans(params);


    }, [limit, page, selectedIsInstalled, searchTerm, selectedPaymentPlan])


    const updateShopDataModalRender = () => {
        return <CustomModal
            open={open}
            setOpen={setOpen}
            title={'Update Shop Data'}
            leftButtonText={'Update'}
            rightButtonText={'Cancel'}
            leftIsLoading={isUpdating}
            rightOnClick={() => setOpen(false)}
            leftOnClick={handelUpdateShopData}
        >
            <Form fluid onChange={setFormValue} formValue={formValue}>
                <Form.Group controlId="first_name">
                    <Form.ControlLabel>First Name</Form.ControlLabel>
                    <Form.Control name="first_name"/>
                </Form.Group>
                <Form.Group controlId="predictions_left">
                    <Form.ControlLabel>Predictions Left</Form.ControlLabel>
                    <Form.Control name="predictions_left" type={'number'}/>
                </Form.Group>
            </Form>
        </CustomModal>
    }


    return (
        <Fragment>
            {updateShopDataModalRender()}
            <CustomDrawer
                backdrop={true}
                size={'full'}
                open={lumaModalOpen}
                setOpen={setLumaModalOpen}
                placement={'right'}
                title={'Luma Subscription Details'}
            >
                <SubscriptionDetails/>
            </CustomDrawer>

            <Panel>
                <Grid fluid style={{marginBottom: 10}}>
                    <Row>
                        <Col>
                            <CustomToolTip
                                toolText={'Search by Store Name, My Shopify Domain, Domain, First Name, Last Name, Email, Shop Owner '}
                            >
                                <Input
                                    // size={'md'}
                                    placeholder="Search by Store Name, My Shopify Domain, Domain, First Name, Last Name, Email, Shop Owner"
                                    value={searchTerm}
                                    onChange={(value) => setSearchTerm(value)}
                                    style={{width: 250}}
                                />
                            </CustomToolTip>
                        </Col>
                        {/*<Col>
                            <CustomToolTip toolText={'Enter Monthly Sales condition. Example: >=50, <=50, All. First Select Operator from dropdown then change the value'}>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: 200 }}>
                                     Dropdown for Operators
                                    <SelectPicker
                                        data={monthlySaleOperatorList}
                                        placeholder="Operator"
                                        style={{ flex: 1 }}
                                        onChange={(value) => setMonthlySaleOperator(value)}
                                        value={monthlySaleOperator}
                                    />
                                     Numeric Input
                                    <InputNumber
                                        placeholder="Value"
                                        disabled={monthlySaleOperator === 'all'} // Disable when "All" is selected
                                        value={monthlySale}
                                        onChange={(value) => setMonthlySale(value)}
                                        style={{ flex: 1 }}
                                    />
                                </div>
                            </CustomToolTip>
                        </Col>*/}


                        <Col>
                            <SelectPicker
                                data={isInstalledData}
                                // size={'md'}
                                style={{width: 200}}
                                onChange={setSelectedIsInstalled}
                                value={selectedIsInstalled}
                            />

                        </Col>

                        <Col>
                            <SelectPicker
                                data={paymentPlans}
                                // size={'md'}
                                style={{width: 200}}
                                loading={isPaymentPlanLoading}
                                onChange={setSelectedPaymentPlan}
                                value={selectedPaymentPlan}
                            />

                        </Col>

                        <Col>
                            <Button
                                onClick={() => handleLumaModalOpen()}
                            >
                                View Luma Usage
                            </Button>

                        </Col>
                    </Row>

                </Grid>
                <Table
                    // height={500}
                    autoHeight={true}
                    hover
                    bordered
                    // width={1310}
                    data={tableData}
                    onRowClick={rowData => {
                        setSelectedRow(rowData);
                        setFormValue({first_name: rowData.first_name, predictions_left: rowData.predictions_left,})
                    }}
                    loading={isLoading || isUpdatingFirstName}

                >
                    <Column width={250} resizable>
                        <HeaderCell>Shop URL</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <Link to={{pathname: '/', search: `?${paramsData}&shop=${rowData.shop_url}`,}}
                                      target={'_blank'}>
                                    {rowData.shop_url}
                                </Link>
                            )}
                        </Cell>
                    </Column>

                    <Column width={230} resizable>
                        <HeaderCell>Domain Website URL</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <a href={`https://${rowData.domain}`} target={'_blank'}>
                                    {rowData.domain}
                                </a>
                            )}
                        </Cell>
                    </Column>

                    {/*<Column width={150} resizable>
                        <HeaderCell>Store Name</HeaderCell>
                        <Cell dataKey="store_name" />
                    </Column>*/}

                    <Column width={80} resizable>
                        <HeaderCell># of monthly Sales</HeaderCell>
                        <Cell dataKey="monthly_sale"/>
                    </Column>

                    <Column width={100} resizable>
                        <HeaderCell>Payment Plan</HeaderCell>
                        <Cell>
                            {rowData => rowData?.payment_plan?.name}
                        </Cell>
                    </Column>

                    <Column width={150} resizable>
                        <HeaderCell>First Name</HeaderCell>
                        <Cell
                            dataKey='first_name'
                            onClick={() => handleOpen()}
                        />
                    </Column>
                    <Column width={210} fullText resizable>
                        <HeaderCell>Email address</HeaderCell>
                        <Cell
                            dataKey='email'
                        />
                    </Column>

                    {/*<Column width={70} resizable>
                        <HeaderCell>Installed</HeaderCell>
                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                rowData.is_installed === 1 ?
                                    <CheckRoundIcon color={'green'} style={{fontSize: 'x-large', marginRight: 10}}/>
                                    :
                                    <WarningRoundIcon color={'red'} style={{fontSize: 'x-large', marginRight: 10}}/>
                            )}
                        </Cell>
                    </Column>*/}

                    <Column width={120} resizable>
                        <HeaderCell>Total Generations</HeaderCell>
                        <Cell dataKey='total_predictions'/>
                    </Column>

                    <Column width={150} resizable>
                        <HeaderCell>Predictions Left</HeaderCell>
                        <Cell
                            dataKey='predictions_left'
                            onClick={() => handleOpen()}
                        />
                    </Column>

                    <Column width={250} resizable fullText={true}>
                        <HeaderCell>Date of Install</HeaderCell>
                        <Cell dataKey="install_date"/>
                    </Column>

                    <Column width={250} resizable fullText={true}>
                        <HeaderCell>Date of 1st video</HeaderCell>
                        <Cell dataKey="first_prediction_date"/>
                    </Column>

                    <Column width={250} resizable fullText={true}>
                        <HeaderCell>Time from install</HeaderCell>
                        <Cell>
                            {rowData => {
                                const sec = rowData.time_from_install
                                const min = Math.ceil(sec / 60);
                                const hour = Math.floor(min / 60);
                                return `${sec} sec (${min} min)`
                            }}
                        </Cell>
                    </Column>

                    {/*<Column width={80} resizable>*/}
                    {/*    <HeaderCell>Free Trial Days</HeaderCell>*/}
                    {/*    <Cell dataKey="free_trail_days" />*/}
                    {/*</Column>*/}


                    <Column width={100} resizable>
                        <HeaderCell>Reset Quota</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <Button appearance="link" onClick={() => handelResetQuota(rowData.shop_url)}
                                        disabled={isResetQuota}>
                                    Reset
                                </Button>
                            )}
                        </Cell>
                    </Column>
                    <Column width={100} resizable>
                        <HeaderCell>Edit</HeaderCell>

                        <Cell style={{padding: '6px'}}>
                            {rowData => (
                                <Button appearance="link" onClick={handleOpen}>
                                    Edit
                                </Button>
                            )}
                        </Cell>
                    </Column>
                </Table>
                <div style={{padding: 20}}>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={totalUserCount}
                        limitOptions={[10, 30, 50]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={(l) => {
                            setLimit(l);
                            setPage(1);
                        }}
                    />
                </div>

            </Panel>
        </Fragment>

    )


}