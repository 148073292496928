import Panel from "rsuite/Panel";
import {
    Button,
    Form,
    Table,
    Toggle,
    Input,
    Placeholder,
    IconButton,
    Pagination,
    SelectPicker,
    ButtonToolbar, Whisper, Tooltip, ButtonGroup, InputGroup, Grid, Row, Col, Dropdown, InputNumber
} from "rsuite";
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import {Link, useNavigate} from "react-router-dom";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {getAuthSession} from "../../../servies/common.services";
import ToasterMessage from "../../../components/common/toaster_message";

import {generatedPredictionApi,} from "../../../servies/settings.services";
import {handleMouseMoveZoom, handleMouseOutZoom} from "../../../components/common/util";
import {Card, Icon, InlineStack, Text} from "@shopify/polaris";
import {Icon as RIcon} from "@rsuite/icons";
import {ReactComponent as thumbsUpActiveSvg} from "../../../assets/thumb_up_active.svg";
import {ReactComponent as thumbsDownActiveSvg} from "../../../assets/thumb_down_active.svg";
import {ReactComponent as questionMarkSvg} from "../../../assets/question-marksvg.svg";
import {getSERVER_URL} from "../../../app.config";
import {CustomToolTip} from "../../../components/common/CustomToolTip";
import SendIcon from '@rsuite/icons/Send';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import {isNull} from "lodash";
import {updateGradeApi} from "../../../servies/admin.services";

const {Column, HeaderCell, Cell} = Table;

export const GeneratedPredictions = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [paramsData, setParamsData] = useState('');
    const [authData, setAuthData] = useState({});
    const [selectedRow, setSelectedRow] = useState({});
    const [predictionsData, setPredictionsData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [usersData, setUsersData] = useState([]);
    const [selectedUser, setSelectedUser] = useState(0);
    const [predictionGrades, setPredictionGrades] = useState({});
    const [isGradeLoading, setIsGradeLoading] = useState(false);
    const [selectedPrediction, setSelectedPrediction] = useState(0);
    const [updatedGrades, setUpdatedGrades] = useState({});
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();


    const navigate = useNavigate();

    const imagesStyle = {
        position: 'relative',
        cursor: 'pointer',
        marginBottom: '16px',
        borderRadius: '6px',
        padding: '10px',
        // width: '200px',
        width: '200px',
        // height: '100px',
        objectFit: 'cover',
        objectPosition: 'center',
    }

    const gradeTableStyle = {
        textAlign: 'center',
        borderCollapse: 'collapse',
        border: '1px solid #000',
    }

    const onGradeChange = (value, prediction_id) => {
        if (value < 0 || value > 10) {
            ToasterMessage.error('Grade should be between 0 and 10');
            return;
        }
        const newPredictionGrades = {...predictionGrades};
        newPredictionGrades[prediction_id] = value;
        setPredictionGrades(newPredictionGrades);
        console.log('newPredictionGrades', newPredictionGrades)
    }

    const getGeneratedPredictions = (params) => {
        const inputData = {
            limit: limit,
            offset: (page - 1) * limit,
            kwargs: {
                search_term: searchTerm,
                sort_column: sortColumn,
                sort_type: sortType,
                user_id: selectedUser
            }
        }
        console.log('limit, page', limit, page)

        setIsLoading(true);
        generatedPredictionApi(params.toString(), inputData)
            .then(res => {
                const data = res.data;

                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                setPredictionsData(data.predictions);
                setTotalData(data.total_data);


                const userDataLabels = data.users_data.map(
                    item => ({
                        label: `${item.count} | ${item.store_name} | ${item.domain}`,
                        value: item.user_id
                    })
                );
                userDataLabels.unshift({label: 'All', value: 0,});
                console.log('userDataLabels', userDataLabels)
                setUsersData(userDataLabels);

            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Settings');
                console.log(reason);
            })
            .finally(() => setIsLoading(false));
    };

    const handelGrandUpdate = (predictionId) => {
        const selectedGrade = predictionGrades[predictionId];
        if (!selectedGrade) {
            ToasterMessage.error('Please enter grade');
            return;
        }
        // predictionGrades[predictionId];
        const inputData = {
            grading: {
                prediction_id: predictionId,
                grade: parseInt(selectedGrade),
                description: ''
            }
        }

        setIsGradeLoading(true);
        setSelectedPrediction(predictionId);
        delete predictionGrades[predictionId];
        setUpdatedGrades({...updatedGrades, [predictionId]: selectedGrade});

        updateGradeApi(paramsData, inputData)
            .then(res => {
                const data = res.data;

                if (data === null) {
                    ToasterMessage.error(res.error);
                    return;
                }

                ToasterMessage.success('Grade updated successfully');
            })
            .catch(reason => {
                ToasterMessage.error(reason.toString() + 'Settings');
                console.log(reason);
            })
            .finally(() => {
                setIsGradeLoading(false);
                setSelectedPrediction(0);
            });


    }

    const handleSortColumn = (sortColumn, sortType) => {
        setSortColumn(sortColumn);
        setSortType(sortType);

        console.log('sortColumn, sortType', sortColumn, sortType)

    };

    useEffect(() => {
        const authSession = getAuthSession();
        if (authSession === null) {
            navigate('/admin/login');
            return;
        }

        const token = authSession.token;
        setAuthData(authSession);
        console.log('authData', authData)

        const params = `token=${token}&is_admin=true`;
        setParamsData(params);

        getGeneratedPredictions(params);


    }, [limit, page, selectedUser, searchTerm, sortColumn, sortType]);


    return (
        <Fragment>
            <Panel>
                <div>
                    <Grid fluid style={{marginBottom: 10}}>
                        <Row>
                            <Col xs={24} sm={12} md={8}>
                                <CustomToolTip
                                    toolText={'Search by Store Name, My Shopify Domain, Domain, First Name, Last Name, Email, Shop Owner '}
                                >
                                    <Input
                                        size={'sm'}
                                        placeholder="Search by Store Name, My Shopify Domain, Domain, First Name, Last Name, Email, Shop Owner"
                                        value={searchTerm}
                                        onChange={(value) => setSearchTerm(value)}
                                        style={{marginBottom: 10, width: 200}}

                                    />
                                </CustomToolTip>
                            </Col>
                            <Col xs={24} sm={12} md={8}>

                                <SelectPicker
                                    data={usersData}
                                    size={'lg'}
                                    style={{width: 300}}
                                    onChange={setSelectedUser}
                                    value={selectedUser}
                                />

                            </Col>
                        </Row>

                    </Grid>
                    <Table
                        height={520}
                        // autoHeight={true}
                        rowHeight={350}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        data={predictionsData}
                        loading={isLoading}
                        onRowClick={data => {
                            setSelectedRow(data);
                        }}
                        bordered={true}
                        rowKey="id"

                    >
                        <Column width={360} resizable>
                            <HeaderCell>Start Image</HeaderCell>

                            <Cell style={{padding: '6px'}}>
                                {rowData => (

                                    <div
                                        style={imagesStyle}
                                        onMouseMove={handleMouseMoveZoom}
                                        onMouseOut={handleMouseOutZoom}
                                    >
                                        <Card>
                                            <img
                                                src={rowData.start_image_url}
                                                style={{width: '100%'}}
                                                alt={''}
                                            />
                                        </Card>

                                    </div>

                                )}
                            </Cell>
                        </Column>

                        <Column width={360} resizable>
                            <HeaderCell>End Image</HeaderCell>

                            <Cell style={{padding: '6px',}}>
                                {rowData => (

                                    <div
                                        style={imagesStyle}
                                        onMouseMove={handleMouseMoveZoom}
                                        onMouseOut={handleMouseOutZoom}
                                    >
                                        <Card>
                                            <img
                                                src={rowData.end_image_url ? rowData.end_image_url : `${getSERVER_URL()}/images/no_image.jpg`}
                                                style={{width: '100%'}}
                                                alt={''}
                                            />
                                        </Card>

                                    </div>
                                )}
                            </Cell>
                        </Column>

                        <Column width={360} resizable>
                            <HeaderCell>Video</HeaderCell>

                            <Cell style={{padding: '6px'}}>
                                {rowData => (

                                    <div
                                        style={{...imagesStyle, height: '240px'}}
                                    >
                                        {rowData.state === 'completed' ?
                                            <Card>

                                                <InlineStack align={'start'} gap={'200'}>
                                                    <video width="100%" controls key={rowData.video.url}>
                                                        <source src={rowData.video.url} type="video/mp4"/>
                                                    </video>
                                                </InlineStack>

                                            </Card>
                                            :
                                            <Placeholder.Graph active width={320} height={240}/>
                                        }
                                    </div>
                                )}
                            </Cell>
                        </Column >

                        <Column width={250} resizable fullText>
                            <HeaderCell>User Prompt</HeaderCell>
                            <Cell >

                                {rowData => (
                                    <div style={{width: '250px', textWrap: 'wrap'}}>
                                        <Text variant="bodyMd" as="span">
                                            {rowData.user_prompt}
                                        </Text>-
                                    </div>
                                )}
                            </Cell>

                        </Column>


                        <Column width={250} resizable sortable>
                            <HeaderCell>Feedback</HeaderCell>


                            <Cell style={{padding: '6px'}} dataKey={'feedback'}>
                                {rowData => (
                                    <>
                                        <Text as={'span'}>{rowData.created_at}</Text>
                                        <br/>
                                        <IconButton
                                            disabled={true}
                                            size={'sm'}
                                            icon={
                                                <RIcon
                                                    as={rowData.feedback ? rowData.feedback.is_thumbs_up ? thumbsUpActiveSvg : thumbsDownActiveSvg : questionMarkSvg}
                                                    style={{fontSize: 35}}
                                                    color={'transparent'}
                                                />
                                            }
                                        />
                                        <br/>
                                        <Link
                                            to={{pathname: '/', search: `?${paramsData}&shop=${rowData.shop_url}`,}}
                                            target={'_blank'}>
                                            {rowData.shop_url}
                                        </Link>
                                        <br/>
                                        <Link
                                            to={rowData.clarity_url}
                                            target={'_blank'}>
                                            Clarity Url
                                        </Link>
                                        <br/>
                                        <Text as={'span'}>
                                            User Prompt: {rowData.user_prompt === "" ? '_' : rowData.user_prompt}
                                        </Text>
                                        <br/>
                                        <br/>
                                        <InputGroup style={{width: 120}}>
                                            <InputNumber
                                                disabled={!isNull(rowData.user_grade) || updatedGrades[rowData.id]}
                                                value={!isNull(rowData.user_grade) ? rowData.user_grade : predictionGrades[rowData.id]}
                                                min={0}
                                                max={10}
                                                onChange={(value) => onGradeChange(value, rowData.id)}
                                            />
                                            <InputGroup.Button
                                                disabled={!isNull(rowData.user_grade) ||
                                                    !predictionGrades[rowData.id] ||
                                                    updatedGrades[rowData.id]

                                                }
                                                loading={isGradeLoading && selectedPrediction === rowData.id}
                                                onClick={() => {
                                                    handelGrandUpdate(rowData.id);
                                                }}
                                            >
                                                <RIcon as={
                                                    updatedGrades[rowData.id] || !isNull(rowData.user_grade)
                                                        ? CheckRoundIcon : SendIcon
                                                } color={'green'}/>
                                            </InputGroup.Button>
                                        </InputGroup>


                                    </>
                                )}
                            </Cell>
                        </Column>

                        <Column width={200} resizable={true}>
                            <HeaderCell>Grading</HeaderCell>
                            <Cell style={{padding: '6px'}}>
                                {rowData => (
                                    <Table
                                        data={rowData.gradings ? rowData.gradings : []}
                                        // width={'60%'}
                                        autoHeight={true}

                                    >
                                        <Column
                                            flexGrow={1}
                                        >
                                            <HeaderCell>User</HeaderCell>
                                            <Cell dataKey={'username'}/>
                                        </Column>
                                        <Column
                                            flexGrow={1}
                                        >
                                            <HeaderCell>Grade</HeaderCell>
                                            <Cell dataKey={'grade'}/>
                                        </Column>
                                    </Table>
                                )}
                            </Cell>
                        </Column>

                    </Table>
                    <div style={{padding: 20}}>
                        <Pagination
                            prev
                            next
                            first
                            last
                            ellipsis
                            boundaryLinks
                            maxButtons={5}
                            size="xs"
                            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                            total={totalData}
                            limitOptions={[10, 30, 50]}
                            limit={limit}
                            activePage={page}
                            onChangePage={setPage}
                            onChangeLimit={(l) => {
                                setLimit(l);
                                setPage(1);
                            }}
                        />
                    </div>
                </div>


            </Panel>
        </Fragment>
    );

}